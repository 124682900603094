



















import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      email: "",
      password: "",
      error: null as any,
    };
  },
  methods: {
    login(e) {
      e.preventDefault();
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.$router.push("/events");
        })
        .catch((e) => {
          this.password = "";
          this.error = "Hoppla, das hat nicht geklappt.";
          if (e.response.data) {
            this.error = e.response.data;
          }
        });
    },
  },
});
